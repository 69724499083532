<template>
    <div>
        <div id="not-found-page">
            <div id="status-code">
                401
            </div>
            <h1>Unauthorized</h1>
            <div id="error-message">
                We're sorry, The credentials you provided are not authorized to access this instance of the application. Please contact support for additional assistance.
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#not-found-page {
    max-width: 500px;
    margin: auto;
    padding-top: 300px;
}
#status-code {
  font-size: 100px;
}
</style>
